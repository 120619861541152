"use client";  // Mark as a Client Component

import { usePathname } from "next/navigation";
import StickyHeader from "./sticky-header";
import Link from "next/link";
import Image from "next/image";
import ReserveFreeBtn from "@/components/landing2/reserve-free-seat";

export default function PathChecker({ children }: { children: React.ReactNode }) {
    const pathname = usePathname();
    const aiscreen = pathname === "/ai-mastermind-workshop";

    return (
        <>
        <header className={`bg-[#FFFFFF] shadow-lg w-full z-50 py-1 ${aiscreen ? '' : 'fixed top-0'}`}>

                    <div className='container flex items-center justify-between'>
                        <div>
                            <Link href="/" className="inline-block">
                            <Image
                                height={218} width={356}
                                className="h-[50px] w-auto"
                                src="https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/home/exc_logo.webp"
                                alt="ExC Academy"
                                blurDataURL="URL"
                                placeholder="blur"
                            />
                            </Link>
                        </div>
                        <div>
                        {aiscreen ?
                            null : <ReserveFreeBtn/> }
                            {/* <Link
                            href='/login'
                            className='hover:bg-gray-800 bg-black font-semibold text-white uppercase px-5 py-2 text-sm lg:inline-block md:inline-block block'>
                                Reserve free seat
                            </Link> */}
                        </div>
                    </div>
                </header>
        {aiscreen ? <StickyHeader /> : null }
        <main className={aiscreen ? "pt-[96px] md:pt-[56px]" : "pt-[56px]"}>
            {children}
        </main>
        </>
    );
}
