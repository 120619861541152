"use client";

import { usePathname } from "next/navigation";
import Image from "next/image";
import { useState, useEffect } from "react";
import { useRouter } from "next/navigation";

export default function StickyHeader() {
  const initialTime = 2 * 24 * 60 * 60; // 2 days in seconds
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const pathname = usePathname();
  const aiscreen = pathname === "/ai-mastermind-workshop";
  const [isScrolled, setIsScrolled] = useState(false);
  const router = useRouter();

  // Load timer value from local storage on mount
  useEffect(() => {
    const storedTime = localStorage.getItem("timeLeft");
    if (storedTime !== null && !isNaN(Number(storedTime))) {
      setTimeLeft(Number(storedTime));
    } else {
      setTimeLeft(initialTime);
    }
  }, [initialTime]);

  // Timer interval that updates local storage on every tick
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        // If timer ends, reset it to the initial value.
        const newTime = prevTime > 0 ? prevTime - 1 : initialTime;
        localStorage.setItem("timeLeft", newTime.toString());
        return newTime;
      });
    }, 1000);
    return () => clearInterval(timer);
  }, [initialTime]);

  // Format the time for display
  const formatTime = (time:any) => {
    const days = Math.floor(time / (24 * 60 * 60));
    const hours = Math.floor((time % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((time % (60 * 60)) / 60);
    const seconds = time % 60;
    return `${days} Days : ${hours} Hours : ${minutes} Minutes : ${seconds} Seconds`;
  };

  // Change header style when scrolling down
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleRegister = () => {
    router.push("/register"); // Change this URL to your desired route
  };

  return (
    <div
      className={`right-0 max-w-full z-10 left-0 w-full bg-[#9F24D9] text-white text-center py-3 transition-transform duration-300 ${
        isScrolled ? "fixed top-0 shadow-lg" : "absolute top-[65px]"
      }`}
    >
      <div className="container">
        <div className="grid md:grid-cols-12 gap-4 items-center">
          <div className="md:col-span-8">
            <p className="font-medium text-[14px] md:text-[16px] flex items-center justify-center md:flex-nowrap flex-wrap">
              <Image
                src={'/images/rocket.png'}
                alt="rocket"
                width={32}
                height={32}
                className="mr-5"
              />
              Grab your 78% discount today!{" "}
              <span className="ml-10">{formatTime(timeLeft)}</span>
            </p>
          </div>
          <div className="md:col-span-4">
            <button
              onClick={handleRegister}
              className="inline-block hover:bg-gray-800 bg-black text-white uppercase px-4 sm:px-7 py-3 sm:py-4 text-center font-semibold text-sm sm:text-base md:text-[16px] lg:inline-block rounded-none leading-[16px] h-[45px] sm:h-[50px] md:h-[45px]"
            >
              Register Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
